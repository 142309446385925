import React from 'react'

// App components
import CustomLink from '../../components/CustomLink'
import Layout from '../../components/layout'

// App content
import CareersAtCollins from '../../content/contact-us/careers-at-collins'
import HtmlSanitize from '../../components/HtmlSanitize'

// App utils
import { themeStyles } from '../../utils/theme'

class Page extends React.Component {
  render() {
    const careersAtCollinsIntro = CareersAtCollins.CAREERS_AT_COLLINS_INTRO
    const careersCollinsMission = CareersAtCollins.COLLINS_MISSION
    const careersCollinsWorkingHard = CareersAtCollins.COLLINS_WORKING_HARD
    const careersCollinsWorkingHardAdd =
      CareersAtCollins.COLLINS_WORKING_HARD_ADD
    const careersCollinsSelfAssessment =
      CareersAtCollins.COLLINS_SELF_ASSESSMENT
    const careersPosition = CareersAtCollins.COLLINS_POSITIONS

    const positions = careersPosition.map((pos) => <li>{pos}</li>)

    return (
      <Layout location={this.props.location}>
        <div css={themeStyles.contentWidth}>
          <div css={themeStyles.textPadding}>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '800px',
                margin: '0 auto',
              }}
            >
              <div
                css={{
                  position: 'relative',
                  overflow: 'hidden',
                  paddingTop: '56.25%',
                  width: '100%',
                }}
              >
                <iframe
                  css={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    border: 0,
                  }}
                  src="https://www.youtube.com/embed/_58k1HA3M4w"
                  frameBorder="0"
                ></iframe>
              </div>
            </div>
            <div css={themeStyles.elementMargin}>
              <p>{careersAtCollinsIntro}</p>
              <p>{careersCollinsMission}</p>

              <p>{careersCollinsWorkingHard}</p>

              <p>{careersCollinsWorkingHardAdd}</p>

              <h1 css={themeStyles.blueText}>Self Assessment</h1>
              <HtmlSanitize
                contentString={careersCollinsSelfAssessment}
              ></HtmlSanitize>

              <h1 css={themeStyles.blueText}>Career Opportunities</h1>
              <p>
                With a positive attitude and determination there is a position
                for you at <strong>Collins Orthodontics</strong>.
              </p>

              <p>Some of our positions include:</p>
              <ul>{positions}</ul>

              <h1 css={themeStyles.blueText}>Apply Today</h1>
              <p>
                If you are interested in becoming part of the Collins
                Orthodontics Team, send your <strong>cover letter</strong> and{' '}
                <strong>resume</strong> to{' '}
                <CustomLink href="mailto:CollinsOrthoCareers@gmail.com">
                  CollinsOrthoCareers@gmail.com
                </CustomLink>
                . In the subject line, list the position(s) you are interested
                in and be sure to tell us why you would be a good fit for the
                Collins Orthodontics Team in the body of the email.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Page
