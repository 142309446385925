const CareersAtCollins = {
  CAREERS_AT_COLLINS_INTRO: `Have you ever worked in a negative workplace? I have. And it’s
    the worst. So when we opened Collins Orthodontics in 2014, we
    wanted to create an environment that was unique from places I’ve
    both worked and visited. A workplace without gossip. Without
    negativity. Without blame. Without fear of making mistakes. We
    wanted to create a place of happiness. A place of rejuvenation.
    A place where employees are encouraged to use their unique
    skillset to grow both personally and professionally.`,
  COLLINS_MISSION: `Our mission at Collins is simple – we serve others. We find joy
    and happiness by creating memorable guest experiences, and we
    will go above and beyond to do so. Essentially, we will do
    whatever it takes to create a patient experience at our office
    that is second to none.`,
  COLLINS_WORKING_HARD: `Here’s the catch. Collins Orthodontics is not an easy place to
    work. In fact, I would argue it may be one of the hardest jobs
    you will ever have. We pride ourselves on "pushing our sleeves
    up" as a team and working hard together. The result – an
    incredibly challenging yet rewarding career.`,
  COLLINS_WORKING_HARD_ADD: `So if you are looking for a positive workplace culture, and you
    think you have the determination, work ethic, strong passion to
    serve others, and willingness to exceed expectations, then keep
    reading to find out how to apply at Collins.`,
  COLLINS_SELF_ASSESSMENT: `Are you a responsible, goal-oriented individual who wants the
    most out of their professional career? Do you thrive on constant
    and never-ending improvement and enjoy the rewards of earned
    success? Are you a positive, hard-working person with a great
    attitude? Do you love to serve others and strive to be the best
    part of their day? If so, there is a position at
    <strong>Collins Orthodontics</strong> waiting for you. We are
    always looking for energetic individuals to join our team. If
    you are confident, pleasant, and willing to learn, we have a
    training program just for you! Experience in the dental field
    and/or orthodontics field is not necessary for any
    administrative positions.`,
  COLLINS_POSITIONS: [
    'Associate Orthodontist',
    'Dental Assistant',
    'Patient Concierge',
    'Treatment Coordinator',
    'Financial Coordinator',
    'Marketing Coordinator',
    'Public Relations Coordinator',
    'Events Coordinator',
    'Social Media Manager',
    'Sterilization Coordinator',
    'Business Development Specialist',
    'Business Growth Expert',
    'Radiologic Technologist (X-Ray Tech)',
  ],
}

export default CareersAtCollins
